<template>
  <b-modal
    id="modal-liturgy"
    title="Special Activities"
    @show="clearData()"
    hide-footer
  >
    <b-form v-on:submit.prevent="onSubmit">
      <h4>{{ stDate }}</h4>
      <b-form-group id="comments" label="Ordo">
        <b-form-textarea
          v-model="newOrdo"
          rows="8"
          max-rows="12"
        ></b-form-textarea>
      </b-form-group>
      <div class="mb-2">
        <b-button class="mr-1" @click="onSave('all', true)" variant="info"
          >Save & Next [All]</b-button
        >
        <b-button class="mr-1" @click="onSave('center', true)" variant="primary"
          >Save & Next [{{ infoUser.password }}]</b-button
        >
      </div>
      <div>
        <b-button class="mr-1" @click="onSave('all', false)" variant="info"
          >Save [All]</b-button
        >
        <b-button
          class="mr-1"
          @click="onSave('center', false)"
          variant="primary"
          >Save [{{ infoUser.password }}]</b-button
        >
        <b-button @click="onCancel" variant="warning">Cancel</b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import moment from "moment";

export default {
  props: ["ordo"],
  data() {
    return {
      newOrdo: "",
    };
  },
  computed: {
    currentDay() {
      return this.$store.state.currentDay;
    },
    infoUser() {
      return this.$store.state.userInfo;
    },
    stDate() {
      return moment(this.$store.state.currentDay).format("dddd, MMMM D");
    },
  },
  methods: {
    onSave(db, next) {
      if (this.newOrdo.length > 0) {
        var url = "updateLiturgyDb.php";
        var data = {
          liturgy: this.newOrdo,
          date: moment(this.currentDay).format("YYYY-MM-DD"),
          db: db,
        };
        this.$http.post(url, data, { emulateJSON: true }).then(
          (res) => {
            if (next) {
              var d = moment(this.currentDay);
              d.add(1, "d");
              this.$store.commit("updateCurrentDay", d.toISOString(true));
              this.newOrdo = "";
            } else {
              this.$bvModal.hide("modal-liturgy");
              this.$emit("ordo-updated");
            }
          },
          (err) => {}
        );
      } else {
        this.validName = false;
      }
    },
    onCancel() {
      this.$bvModal.hide("modal-liturgy");
    },
    clearData() {
      this.newOrdo = this.ordo;
    },
  },
};
</script>

<style>
</style>