<style scoped>
.notes-info-no-mass {
  font-family: "Handlee", cursive;
  color: #ca0000;
  font-size: 1.2em;
}
.notes-info-mass {
  font-family: "Handlee", cursive;
  color: #002288;
  font-size: 1.2em;
}
</style>

<template>
  <div title="Liturgy" class="ml-1">
    <edit-ordo v-bind:ordo="dayOrdo" v-on:ordo-updated="getLiturgy()" />
    <nav-row />
    <b-row align-v="center">
      <b-col cols="auto" v-if="(infoUser.status & 4) > 0">
        <b-button @click="$bvModal.show('modal-liturgy')" variant="light"
          >Edit</b-button
        >
      </b-col>
      <b-col
        cols="auto"
        class="notes-info-no-mass"
        v-if="dayMass == '' && dbName"
        >No Mass</b-col
      >
      <b-col cols="auto" class="notes-info-mass" v-else>{{
        dayMass.Title
      }}</b-col>
    </b-row>

    <div v-if="loadingOrdo">
      <font-awesome-icon
        icon="sync-alt"
        spin
        size="3x"
        :style="{ color: 'blue' }"
      />
    </div>
    <b-form-textarea
      v-else-if="dayOrdo != ''"
      id="liturgy-tab-textarea"
      plaintext
      max-rows="18"
      :value="dayOrdo"
    ></b-form-textarea>
    <div v-else>"Sorry, it has not been updated... Come back later..."</div>

    <div class="text-left mt-4 text-muted">
      <small>Info updated at {{ lastUpdatedTime }}</small>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import NavRow from "@/components/NavRow.vue";
import EditOrdo from "@/components/EditOrdo.vue";

export default {
  data: function () {
    return {
      dayOrdo: "",
      dayMass: "",
      loadingOrdo: false,
      count: 0,
      lastUpdated: moment(),
      idTimeInterval: 0,
    };
  },
  computed: {
    currentDay() {
      return this.$store.state.currentDay;
    },
    infoUser() {
      return this.$store.state.userInfo;
    },
    reloadData() {
      return this.$store.state.reloadData;
    },
    lastUpdatedTime() {
      return moment(this.lastUpdated).format("h:mm a");
    },
    dbName() {
      return this.$store.state.settings.db.name;
    },
  },
  components: {
    NavRow,
    EditOrdo,
  },
  mounted() {
    this.$store.commit("updateCurrentDayToday");
    this.idTimeInterval = setInterval(this.updateEachHour, 6 * 60 * 60 * 1000);
  },
  destroyed() {
    clearInterval(this.idTimeInterval);
  },

  watch: {
    currentDay: function () {
      this.getLiturgy();
    },
    reloadData: function () {
      if (this.reloadData) {
        this.getLiturgy();
        this.$store.commit("reloadData", false);
      }
    },
  },
  methods: {
    getLiturgy() {
      clearInterval(this.idTimeInterval);
      this.idTimeInterval = setInterval(
        this.updateEachHour,
        6 * 60 * 60 * 1000
      );
      this.lastUpdated = moment();
      this.count++;
      var url = "getLiturgy.php";
      var date = moment(this.currentDay).format("YYYY-MM-DD");
      var data = { date: date };
      this.dayOrdo = "";
      this.dayMass = "";
      this.loadingOrdo = true;
      this.$http.post(url, data, { emulateJSON: true }).then(
        (res) => {
          this.dayOrdo = res.body["todayLit"];
          this.dayMass = res.body["todayMasses"];
          this.loadingOrdo = false;
          this.count = 0;
        },
        () => {
          this.loadingOrdo = false;
          if (this.count < 10) {
            setTimeout(this.getLiturgy, 3000);
            this.$bvToast.toast(`Trying to reconect...`, {
              title: "Connecction lost",
              variant: "info",
              toaster: "b-toaster-top-center",
              autoHideDelay: 2500,
              appendToast: false,
            });
          } else {
            this.$bvToast.toast(
              `Sorry, check your Internet connection and try again...`,
              {
                title: "Connecction lost",
                variant: "info",
                toaster: "b-toaster-top-center",
                noAutoHide: true,
                appendToast: false,
              }
            );
          }
        }
      );
    },
    updateEachHour() {
      if (moment().hour() > 4 && moment().hour() < 20) {
        this.$store.commit("updateCurrentDayToday");
      }
    },
  },
};
</script>